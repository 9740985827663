<template>
  <TTView>
    <VRow>
      <VCol>
        <SapIdForm
          :entity="sapData"
          @update:pernr="sapData.pernr = $event"
          @update:sapId="sapData.sapId = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import SapIdForm from '../../components/authentication/SapIdForm.vue';

export default {
  name: 'UserAddSapId',

  components: {
    SapIdForm,
  },

  data() {
    return {
      loading: false,
      sapData: {
        pernr: '',
        sapId: 'SEVERSTAL',
      },
    };
  },

  computed: {
    userId() {
      return this.$route.params.userId;
    },
  },
  methods: {
    async handleSubmit() {
      try {
        this.loading = true;

        const payload = {
          pernr: this.sapData.pernr,
          sapId: this.sapData.sapId,
          id: this.userId,
        };

        await this.$di.api.Authentication.addSapId(payload);
        this.$router.go(-1);
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
