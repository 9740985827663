<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      Добавление SAP ID
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.pernr"
                label="pernr"
                name="pernr"
                persistent-hint
                hint="Заполните pernr"
                @input="$emit('update:pernr', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.sapId"
                label="SAP ID"
                name="sapId"
                persistent-hint
                hint="Заполните SAP ID"
                @input="$emit('update:sapId', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        Добавить
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'SapIdForm',

  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
